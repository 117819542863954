.container {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (--screen--xs-max) {
    padding-right: 16px;
    padding-left: 16px;
  }

  @media (--screen--sm-min) {
    width: 1920px;
    padding-right: 56px;
    padding-left: 56px;
  }
}

.grid {
  @media (--screen--sm-min) {
    display: grid;
    grid-column-gap: 56px;
  }

  &--col-12 {
    @media (--screen--sm-min) {
      grid-template-columns: repeat(12, 1fr);
    }
  }
}
