:root {
  --hsl--black: 0, 0%, 0%;
  --hsl--white: 0, 0%, 100%;
  --hsl--red: 0, 100%, 59%;
  --hsl--blood: 0, 70%, 53%;
  --color--black: hsl(var(--hsl--black));
  --color--white: hsl(var(--hsl--white));
  --color--red: hsl(var(--hsl--red));
  --color--blood: hsl(var(--hsl--blood));
  --width--audio-progress: 0%;
  --translate--quote-list: 0;
  --height--quote-slider: auto;
}

@custom-media --screen--xs-max screen and (max-width: 767px);
@custom-media --screen--sm-min screen and (min-width: 768px);
