@font-face {
  /* font-display: swap; */
  font-family: "Monument Extended";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/MonumentExtended_Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Neue Machina";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/NeueMachina_Ultrabold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "PT Root UI Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/PT_Root_UI_Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "PT Root UI Regular";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/PT_Root_UI_Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Object Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/ObjectSans_Regular.woff2") format("woff"),
    url("./fonts/ObjectSans_Regular.woff") format("woff");
}
