#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 1031;
  top: 50%;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: #ff2e2e;
  transform: translateY(-50%);
  will-change: width, transform;
}
