.Wrapper {
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.WrapperExitActive {
  .Text {
    opacity: 0;
    transform: translateY(80px);
    transition: opacity 0.5s, transform 0.5s;
  }

  /* .Title {
    span {
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.6s, opacity 0.6s;
    }
  } */

  .Curtains {
    .Curtain {
      transition: transform 0.7s;
      transition-delay: 0.5s;

      &:nth-child(1) {
        transform: translateY(-100%);
      }

      &:nth-child(2) {
        transform: translateY(100%);
      }
    }
  }
}

.Curtains {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.Curtain {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #000;
  will-change: transform;
}

.TextContainer {
  /* margin-top: auto; */

  @media (--screen--sm-min) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

/* .Title {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  color: transparent;
  opacity: 0.2;
  -webkit-text-stroke: 2px var(--color--white);
  transform: translate(-50%, -50%);

  span {
    display: inline-block;
  }
} */

.Text {
  padding-bottom: 80px;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  @media (--screen--sm-min) {
    grid-column: 5/4 span;
  }
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 160px;
  grid-column: 5 / 4 span;
}

.ButtonDesktop {
  display: none;

  @media (--screen--sm-min) {
    display: block;
  }
}

.ButtonMobile {
  display: block;

  @media (--screen--sm-min) {
    display: none;
  }
}
