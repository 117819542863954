*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;

  &[data-state="no-scroll"] {
    overflow: hidden;
  }

  &::-webkit-scrollbar-track {
    background: #000;
  }

  &::-webkit-scrollbar {
    width: 1px;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color--red);
  }
}

body {
  min-height: 100%;
  background-attachment: fixed;
  background-color: var(--color--black);
  background-image: url("./assets/bg.jpg");
  background-position: center;
  background-size: contain;
  color: var(--color--white);
  font-family: "PT Root UI Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;

  &[data-state="no-scroll"] {
    overflow: hidden;
    height: 100%;
  }

  @media (--screen--xs-max) {
    min-width: 320px;
    font-size: 14px;
    line-height: 22px;
  }

  @media (--screen--sm-min) {
    min-width: 1920px;
    font-size: 24px;
    line-height: 36px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: currentColor;
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

img:not([class]),
svg:not([class]) {
  display: block;
  max-width: 100%;
}

#root {
  &[data-state="zoom-out-start"],
  &[data-state="zoom-out-end"] {
    overflow: hidden;
  }
}

.undefined {
  background: red !important;
  color: red !important;
}

.mark {
  color: var(--color--red);
}

.headroom-wrapper {
  position: relative;
  z-index: 3;

  [data-state="zoom-out-start"] &,
  [data-state="zoom-out-end"] & {
    opacity: 0;
    transition: opacity ease 0.5s;
  }
}

.swiper-button-disabled {
  cursor: default !important;
  pointer-events: none;
}

.link {
  @media (--screen--sm-min) {
    color: var(--color--red);

    &::before {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0;
      box-shadow: 0 0 0 1px var(--color--red);
      content: "";
      opacity: 0.4;
      transition: transform ease-in-out 0.2s, opacity ease-in-out 0.2s;
      will-change: transform;
    }

    &:hover {
      &::before {
        opacity: 1;
        transform: translateY(-15px);
      }
    }
  }
}

#gl-wrap {
  position: relative;
}

#gl-back {
  @media (--screen--xs-max) {
    display: none;
  }

  @media (--screen--sm-min) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
